<template>
	<Alert v-if="error" :message="error" />

	<div class="cl-container">
		<!-- loading shimmer whilst we fetch the checklist -->
		<div class="cl-body" v-if="!checklist">
			<div class="cover-page">
				<div class="row">
					<div class="spacer-40"></div>
					<div class="logo">
						<div class="img-shimmer shine"></div>
					</div>
					<div class="spacer-40"></div>
				</div>
				<div class="row">
					<div class="title">
						<div class="title-shimmer shine"></div>
					</div>
				</div>
				<div class="cover-page-bottom">
					<div class="detail">
						<div class="lines shine"></div>
						<div class="lines shine"></div>
					</div>
					<div class="detail">
						<div class="lines shine"></div>
						<div class="lines shine"></div>
					</div>
					<div class="detail">
						<div class="lines shine"></div>
						<div class="lines shine"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Show a less detailed cover page if user has no access -->
		<div class="cl-body" v-if="checklist && !pageAccess">
			<div class="cover-page">
				<div class="row archived-banner">
					<p>
						<b>Access Denied</b>
					</p>
				</div>
				<div class="row">
					<div class="spacer-40"></div>
					<div class="logo">
						<img
							crossorigin="*"
							class="img-responsive"
							:src="checklist.companyDoc.companyLogo"
						/>
					</div>
					<div class="spacer-40"></div>
				</div>
				<div class="row">
					<div class="title">
						<h1>{{ checklist.name }}</h1>
					</div>
				</div>
				<div class="cover-page-bottom">
					<div class="detail">
						<h4>Conducted by:</h4>
						<p>{{ checklist.ownername }}</p>
					</div>
					<div class="detail" v-if="checklist.location.name">
						<h4>Conducted for:</h4>
						<p>
							<b>{{ checklist.companyName }}</b>
						</p>
					</div>
					<div
						class="detail"
						v-if="checklist && checklist.organization.name"
					>
						<h4>Conducted for:</h4>
						<p>
							<b>{{ checklist.companyName }}</b>
						</p>
						<p>{{ checklist.organization.name }}</p>
					</div>

					<div class="detail">
						<h4 v-if="checklist.status != 'scheduled'">
							Conducted:
						</h4>
						<p v-if="checklist.status != 'scheduled'">
							Started at:
							{{
								moment(checklist.createdAt.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p
							v-if="
								checklist.scheduledDate != undefined &&
								checklist.status == 'scheduled'
							"
						>
							<b>Scheduled for:</b>
							{{
								moment(checklist.scheduledDate.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p
							v-if="
								checklist.completedAt != undefined &&
								markedComplete == null
							"
						>
							Completed:
							{{
								moment(checklist.completedAt.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p v-if="checklist.status == 'active'">
							Completed:
							<span class="danger">
								<b>Incomplete</b>
							</span>
						</p>
						<p v-if="markedComplete && completedOn">
							Completed:
							{{
								moment(Date(completedOn)).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
					</div>
					<div class="detail" v-if="!isLoading">
						<p style="margin-bottom: 6px">
							<b>File name:</b>
							{{ filename }}
						</p>
						<p>
							<b>Unique ID:</b>
							{{ props.id }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="cl-header" v-if="checklist && pageAccess">
			<!-- Only show if the pdf has been generated, not downloading and file name retrieved -->
			<div v-if="checklist.status != 'scheduled'">
				<div
					class="btn-icon"
					v-if="!downloading && !generatingPdf && filename && pdf"
				>
					<div class="text" @click="downloadPdf">Download PDF</div>
					<fa icon="file-pdf" />
				</div>
				<div v-else>
					<p v-if="!downloading">Generating pdf...</p>
				</div>
			</div>
			<div
				class="btn-icon muted"
				v-if="
					!downloading &&
					checklist &&
					checklist.status == 'active' &&
					editAccess
				"
			>
				<div class="text" @click="markChecklistAsComplete">
					Mark Complete
				</div>
				<fa icon="check-circle" />
			</div>
			<div
				class="btn-icon muted"
				v-if="
					!downloading &&
					checklist &&
					checklist.status == 'complete' &&
					editAccess
				"
			>
				<div class="text" @click="markChecklistAsActive">
					Mark Active
				</div>
				<fa icon="undo" />
			</div>
			<div
				class="btn-icon muted"
				v-if="
					!downloading &&
					checklist &&
					!checklist.isArchived &&
					editAccess
				"
			>
				<div class="text" @click="archiveChecklist">Archive</div>
				<fa icon="archive" />
			</div>
			<div
				class="btn-icon muted"
				v-if="
					!downloading &&
					checklist &&
					checklist.isArchived &&
					editAccess
				"
			>
				<div class="text" @click="unArchiveChecklist">Un-archive</div>
				<fa icon="undo" />
			</div>
			<div class="btn-loading" v-if="downloading || !checklist">
				<page-loading />
			</div>
		</div>

		<!-- PDF content -->
		<div
			class="cl-body"
			v-if="checklist && pageAccess"
			id="element-to-print"
		>
			<div class="cover-page">
				<div class="row archived-banner" v-if="checklist.isArchived">
					<p>
						<b>Archived Checklist</b>
					</p>
				</div>
				<div class="row">
					<div class="spacer-40"></div>
					<div class="logo">
						<img
							crossorigin="*"
							class="img-responsive"
							:src="checklist.companyDoc.companyLogo"
						/>
					</div>
					<div class="spacer-40"></div>
				</div>
				<div class="row">
					<div class="title">
						<h1>{{ checklist.name }}</h1>
					</div>
				</div>
				<div class="cover-page-bottom">
					<div class="detail">
						<h4>Conducted by:</h4>
						<p>{{ checklist.ownername }}</p>
						<p v-if="companyName">{{ companyName }}</p>
						<a
							v-if="filename"
							:href="
								'mailto:' +
								checklist.ownerEmail +
								'?subject=' +
								filename
							"
							>{{ checklist.ownerEmail }}</a
						>
					</div>
					<div
						class="detail"
						v-if="checklist && checklist.location.name"
					>
						<h4>Conducted for:</h4>
						<p>
							<b>{{ checklist.companyName }}</b>
						</p>
						<p>{{ checklist.location.name }}</p>
						<p>{{ checklist.location.address }}</p>
						<p>{{ checklist.location.state }}</p>
						<p>{{ checklist.location.country }}</p>
						<p>{{ checklist.location.zip }}</p>
					</div>
					<div
						class="detail"
						v-if="checklist && checklist.organization.name"
					>
						<h4>Conducted for:</h4>
						<p>
							<b>{{ checklist.companyName }}</b>
						</p>
						<p>{{ checklist.organization.name }}</p>
						<p>{{ checklist.organization.address1 }}</p>
						<p>{{ checklist.organization.address2 }}</p>
						<p>{{ checklist.organization.state }}</p>
						<p>{{ checklist.organization.country }}</p>
						<p>{{ checklist.organization.zip }}</p>
					</div>

					<div class="detail">
						<h4 v-if="checklist.status != 'scheduled'">
							Conducted:
						</h4>
						<p v-if="checklist.status != 'scheduled'">
							Started at:
							{{
								moment(checklist.createdAt.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p
							v-if="
								checklist.scheduledDate != undefined &&
								checklist.status == 'scheduled'
							"
						>
							<b>Scheduled for:</b>
							{{
								moment(checklist.scheduledDate.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p
							v-if="
								checklist.completedAt != undefined &&
								checklist.status == 'complete'
							"
						>
							Completed:
							{{
								moment(checklist.completedAt.toDate()).format(
									"MMMM Do YYYY, h:mm a"
								)
							}}
						</p>
						<p v-if="checklist.status == 'active'">
							Status:
							<span class="danger">
								<b>Active</b>
							</span>
						</p>
					</div>
					<div class="detail" v-if="!isLoading">
						<p style="margin-bottom: 6px">
							<b>File name:</b>
							{{ filename }}
						</p>
						<p>
							<b>Unique ID:</b>
							{{ props.id }}
						</p>
					</div>
				</div>
			</div>

			<div class="html2pdf__page-break"></div>

			<!-- Uncomment to add actions again -->
			<div class="page" v-if="checklist && checklist.actions.length">
				<div class="page-title">
					<h2>Actions</h2>
				</div>
				<div
					class="actions-list"
					v-for="action in checklist.actions"
					:key="action.id"
				>
					<ActionListRow :action="action" :isChecklist="true" />
				</div>
			</div>

			<div class="html2pdf__page-break"></div>

			<page-loading v-if="!checklist" />
			<div v-else v-for="page in checklist.pages" :key="page.id">
				<div class="page" v-if="pageHasValues(page)">
					<div class="page-title">
						<h2>{{ page.title }}</h2>
					</div>

					<div v-for="section in page.sections" :key="section.id">
						<div class="section" v-if="sectionHasValues(section)">
							<div class="section-title">
								<h3>{{ section.title }}</h3>
							</div>
						</div>
						<div v-if="section.repeat == false">
							<div
								v-for="ques in section.questions"
								:key="ques.id"
							>
								<div
									v-if="
										ques.type == 'Checkbox' &&
										ques.value != null &&
										ques.value != ''
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'DateTime' &&
										(ques.assignedDate || ques.assignedTime)
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'Image' &&
										ques.photos &&
										ques.photos.length
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'Text' &&
										ques.value != null &&
										ques.value != ''
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>
								<div
									v-else-if="
										ques.type == 'MultiChoice' &&
										hasSelected(ques.choices)
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'Equipment' &&
										hasLength(ques.equipments)
									"
								>
									<div class="spacer-20"></div>
									<div class="question-container">
										<div class="equipment-message">
											<fa icon="question-circle" />
											<i>
												The current equipment statuses
												listed below may have changed
												since this report was created</i
											>
										</div>
									</div>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'People' &&
										hasLength(ques.users)
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div
									v-else-if="
										ques.type == 'Location' && ques.value
									"
								>
									<div class="question-container">
										<Question :question="ques" />
									</div>
								</div>

								<div v-else-if="ques.type == 'Instruction'">
									<Question :question="ques" />
								</div>

								<div
									v-else-if="
										ques.type == 'Signature' &&
										ques.signatures &&
										ques.signatures.length
									"
								>
									<Question :question="ques" />
								</div>
								<div v-else></div>

								<div class="actions">
									<!-- INCOMPLETE ACTIONS -->
									<div
										class="incomplete"
										v-if="hasLength(ques.incompleteActions)"
									>
										<div class="header">
											<h4>Incomplete Actions</h4>
										</div>

										<div
											class="actions-list"
											v-for="action in ques.incompleteActions"
											:key="action.id"
										>
											<ActionListRow
												:action="action"
												:isChecklist="true"
											/>
										</div>
									</div>

									<!-- COMPLETE ACTIONS -->
									<div
										class="complete"
										v-if="hasLength(ques.completedActions)"
									>
										<div class="header">
											<h4>Completed Actions</h4>
										</div>

										<div
											class="actions-list"
											v-for="action in ques.completedActions"
											:key="action.id"
										>
											<ActionListRow
												:action="action"
												:isChecklist="true"
											/>
										</div>
									</div>

									<!-- Question Notes -->
									<div class="notes" v-if="ques.notes">
										<div class="header">
											<h4>Notes</h4>
										</div>
										<div class="content">
											<p>{{ ques.notes }}</p>
										</div>
									</div>

									<!-- Question Images -->
									<div
										class="images"
										v-if="
											ques.actionPhotos &&
											ques.actionPhotos.length
										"
									>
										<div class="header">
											<h4>Images</h4>
										</div>
										<div class="image-grid">
											<div
												v-for="photo in ques.actionPhotos"
												:key="photo"
											>
												<div
													class="image"
													v-if="
														!photo.startsWith('u')
													"
												>
													<router-link
														:to="{
															name: 'ChecklistImageView',
															query: {
																photo: photo,
															},
														}"
													>
														<img
															crossorigin="*"
															v-bind:src="photo"
														/>
													</router-link>
												</div>
												<div v-else>
													<img
														src="@/assets/image-placeholder.png"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="html2pdf__page-break"></div>
				</div>
			</div>
		</div>
		<!-- PDF content end -->
		<div class="cl-footer">
			<p>
				<a href="http://riskmemo.com">riskmemo.com</a>
			</p>
		</div>
	</div>
	<transition name="fade" mode="out-in">
		<router-view></router-view>
	</transition>
</template>

<script>
import { defineComponent } from "vue";
import { onBeforeMount, ref, computed } from "@vue/runtime-core";
import {
	appCheck,
	projectFirestore,
	projectStorage,
	timestamp,
} from "../../firebase/config";
import PageLoading from "../../components/PageLoading.vue";
import checklistCollection from "../../composables/checklistcollection";
import SvcUtils from "../../misc/env";
import generatePdf from "@/composables/getPdf.js";
import Alert from "../../components/Alert.vue";
import { useMeta } from "vue-meta";
import moment from "moment";
import useDocument from "../../composables/useDocument";
import useCookies from "../../composables/useCookies";
import account from "../../composables/account";
import ILovePDFApi from "@ilovepdf/ilovepdf-nodejs";
import userCollection from "../../composables/userCollection";
import ActionListRow from "../../components/actions/ActionListRow.vue";

// Question components
import Question from "../../components/checklists/questions/Question.vue";

export default defineComponent({
	components: { PageLoading, Alert, Question, ActionListRow },
	name: "ChecklistDetail",
	props: ["id"],

	setup(props) {
		useMeta({ title: "Checklist Report" });
		const { getCookie } = useCookies();
		const cookieId = getCookie("rm_cId");
		const { user, companyId, userDoc, role } = account();
		const { error, getChecklist } = checklistCollection();
		const { getEmployeesFromList, getEmployeeProfile } = userCollection();

		const checklist = ref(null);

		const checklistRef = projectFirestore
			.collection("Checklist")
			.doc(props.id);

		const completedOn = ref();
		const isLoading = ref(true);
		const downloading = ref(false);
		const filename = ref();

		const instance = new ILovePDFApi(
			SvcUtils.ILOVEPDF_PUBLIC_KEY,
			SvcUtils.ILOVEPDF_SECRET_KEY
		);

		const pdf = ref(null);

		const generatingPdf = ref(false);

		// Generate the pdf and save to clould storage (via cloud function) to save loading time
		const generateReport = async () => {
			generatingPdf.value = true;
			error.value = null;

			generatePdf(props.id)
				.then((res) => {
					pdf.value = res;
				})
				.catch((err) => {
					console.log(err);
					generatingPdf.value = false;
					error.value = err.message;
				})
				.finally(() => {
					generatingPdf.value = false;
				});
		};

		const companyName = ref("");
		const companyLogo = ref("");
		const fullName = ref("");
		const checklistUsers = ref([]);

		const employeeDoc = ref();

		onBeforeMount(async () => {
			generateReport();

			checklist.value = await getChecklist(props.id);

			// Get the checklist users
			checklistUsers.value = await getEmployeesFromList(
				checklist.value.users,
				checklist.value.companyId
			);

			// Corrects the company name when it's completed for a third party
			let authorCompanyDoc = await projectFirestore
				.collection("companies")
				.doc(checklist.value.authorDoc.companyId)
				.get();

			companyName.value = authorCompanyDoc.data().companyName;
			companyLogo.value = authorCompanyDoc.data().companyLogo;

			// get user employee doc
			if (authorCompanyDoc.exists) {
				const authorID = authorCompanyDoc.data().ownerUid;

				employeeDoc.value =
					(await getEmployeeProfile(user.value.uid, companyId)) ||
					null;
			}

			filename.value =
				checklist.value.name +
				"--" +
				moment(checklist.createdAt).format("DD_MM_YYYY");

			isLoading.value = false;
		});

		const pageAccess = computed(() => {
			// If Risk Memo Admin
			if (user.value.email == "admin@riskmemo.com") {
				return true;
			}

			// Manages the checklist location
			if (
				Object.keys(checklist.value.location || {}).length > 0 &&
				(employeeDoc.value?.manages?.includes(
					checklist.value.location.id
				) ||
					false)
			) {
				return true;
			}

			if (checklist.value) {
				// added to access ids (third party actions etc)
				if (checklist.value.accessIds) {
					if (checklist.value.accessIds.includes(user.value.uid)) {
						return true;
					}
				}

				// If a checklist collaborator
				var collaborators = [];

				if (checklist.value.checklistCollaborators) {
					checklist.value.checklistCollaborators.forEach((person) => {
						collaborators.push(person.id);
					});
				}

				if (collaborators.includes(user.value.uid)) {
					return true;
				}

				// If shared with user
				var sharedWith = [];

				if (checklist.value.sharedCompletedChecklistWithPeople) {
					checklist.value.sharedCompletedChecklistWithPeople.forEach(
						(person) => {
							sharedWith.push(person.id);
						}
					);
				}

				if (sharedWith.includes(user.value.uid)) {
					return true;
				}

				// If shared with email
				if (checklist.value.sharedEmails) {
					if (
						checklist.value.sharedEmails.includes(user.value.email)
					) {
						return true;
					}
				}

				if (
					checklist.value.status === "complete" &&
					checklist.value.sharedCompletedChecklistWithPeople.includes(
						user.value.uid
					)
				) {
					return true;
				}

				// If attached to checklist
				if (checklist.value.users) {
					if (checklist.value.users.includes(user.value.uid)) {
						return true;
					}
				}

				// If the ower of the doc
				if (user.value.uid == checklist.value.owner) {
					return true;
				}

				// If admin of author company
				if (
					companyId == checklist.value.authorDoc.companyId &&
					role.value == "Admin"
				) {
					return true;
				}

				// If Admin of companyId
				if (
					companyId == checklist.value.companyId &&
					role.value == "Admin"
				) {
					return true;
				}
			}

			return false;
		});

		const editAccess = computed(() => {
			// If Risk Memo Admin
			if (user.value.email == "admin@riskmemo.com") {
				return true;
			}

			// Manages the checklist location
			if (
				Object.keys(checklist.value.location || {}).length > 0 &&
				(employeeDoc.value?.manages?.includes(
					checklist.value.location.id
				) ||
					false)
			) {
				return true;
			}

			if (checklist.value) {
				// If a checklist collaborator
				var collaborators = [];

				if (checklist.value.checklistCollaborators) {
					checklist.value.checklistCollaborators.forEach((person) => {
						collaborators.push(person.id);
					});
				}

				if (collaborators.includes(user.value.uid)) {
					return true;
				}

				// If the ower of the doc
				if (user.value.uid == checklist.value.owner) {
					return true;
				}

				// If admin of author company
				if (
					companyId == checklist.value.authorDoc.companyId &&
					role.value == "Admin"
				) {
					return true;
				}
			}

			return false;
		});

		const downloadPdf = async () => {
			if (!pdf.value) {
				return;
			}
			downloading.value = true;
			error.value = null;
			var fileUrl = null;
			const text = await new Response(pdf.value).text();

			const gsReference = projectStorage.ref(text);

			let appCheckTokenResponse;

			// Get the current token.
			try {
				appCheckTokenResponse = await appCheck.getToken(
					/* forceRefresh= */ false
				);
				console.log("Token retrieved");
			} catch (err) {
				// Handle any errors if the token was not retrieved.
				console.error("INVALID APP CHECK TOKEN");
				return;
			}

			await gsReference
				.getDownloadURL({
					headers: {
						"X-Firebase-AppCheck": appCheckTokenResponse.token,
					},
				})
				.then((url) => {
					fileUrl = url;
				})
				.catch((err) => {
					error.value = err.code;
					downloading.value = false;
				});

			if (!error.value) {
				const task = instance.newTask("compress");

				task.start()
					.then(() => {
						return task.addFile(fileUrl);
					})
					.then(() => {
						return task.process({
							compression_level: "recommended",
						});
					})
					.then(() => {
						return task.download();
					})
					.then((data) => {
						const url = window.URL.createObjectURL(
							new Blob([data])
						);
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", filename.value + ".pdf"); //or any other extension
						document.body.appendChild(link);
						link.click();
						downloading.value = false;
					})
					.catch((err) => {
						console.error("PDF Compress Error: ", err);
						error.value = err.code;
						downloading.value = false;

						// If compression fails, open directly from storage
						if (fileUrl != null) {
							window.open(fileUrl);
						} else {
							error.value =
								"Something went wrong. Refresh and try again.";
						}
					})
					.finally(() => {
						downloading.value = false;
					});
			}
		};

		// Returns true if a multichoice question has been selected
		// and therefore should display
		const hasSelected = (choices) => {
			let hasValue = false;

			choices.forEach((choice) => {
				if (choice.value == true) {
					hasValue = choice.value;
				}
			});

			return hasValue;
		};

		// Returns true if a list has items
		// and therefore should display
		const hasLength = (list) => {
			let hasLength = false;
			if (list && list.length > 0) {
				hasLength = true;
			}

			return hasLength;
		};

		// Determin if a page should be displayed
		const pageHasValues = (page) => {
			let pageHasValue = false;
			let questionsHaveValue = false;

			page.sections.forEach((section) => {
				questionsHaveValue = sectionHasValues(section);

				if (questionsHaveValue) {
					pageHasValue = true;
				}
			});

			return pageHasValue;
		};

		// Determin if a section should be displayed
		const sectionHasValues = (section) => {
			// let questionsHaveValue = false;
			let sectionHasValue = false;

			if (section != undefined) {
				section.questions.forEach((question) => {
					if (question.type != "Instruction") {
						if (questionHasValue(question)) {
							sectionHasValue = true;
						}
						// questionsHaveValue = questionHasValue(question);
					} else {
						if (!section.repeat) {
							sectionHasValue = true;
						}
					}
				});
			}

			return sectionHasValue;
		};

		// Determin if a question should be displayed
		const questionHasValue = (question) => {
			var questionHasValue = false;
			if (
				question.value != undefined &&
				question.value != null &&
				question.value != ""
			) {
				questionHasValue = true;
			}

			if (question.type == "Equipment") {
				if (
					question.equipmentDetails &&
					question.equipmentDetails.length
				) {
					questionHasValue = true;
				}
			}

			if (question.type == "People") {
				if (question.users && question.users.length) {
					questionHasValue = true;
				}
			}

			if (question.type == "MultiChoice") {
				if (hasSelected(question.choices)) {
					questionHasValue = true;
				}
			}
			return questionHasValue;
		};

		// Manage the status of the checklist - active, complete and archive
		const archiveChecklist = async () => {
			error.value = null;
			downloading.value = true;

			var date = timestamp();

			const { updateDoc } = useDocument(checklistRef);

			const updates = {
				isArchived: true,
				archivedDate: date,
			};

			try {
				await updateDoc(updates);
			} catch (e) {
				error.value = "Error archiving checklist";
				downloading.value = false;
			}
			checklist.value.isArchived = true;
			downloading.value = false;
		};

		const unArchiveChecklist = async () => {
			error.value = null;
			downloading.value = true;

			const { updateDoc } = useDocument(checklistRef);

			const updates = {
				isArchived: false,
				archivedDate: null,
			};

			try {
				await updateDoc(updates);
			} catch (e) {
				error.value = "Error un-archiving checklist";
				downloading.value = false;
			}
			checklist.value.isArchived = false;
			downloading.value = false;
		};

		const markedComplete = ref(null);

		const markChecklistAsComplete = async () => {
			error.value = null;
			downloading.value = true;
			var date = timestamp();

			const { updateDoc } = useDocument(checklistRef);

			const updates = {
				status: "complete",
				completedAt: date,
				updatedAt: date,
			};

			try {
				await updateDoc(updates);
			} catch (e) {
				error.value = "Error marking checklist complete";
				downloading.value = false;
			}
			checklist.value.status = "complete";
			completedOn.value = date;
			markedComplete.value = true;
			downloading.value = false;
		};

		const markChecklistAsActive = async () => {
			error.value = null;
			downloading.value = true;
			var date = timestamp();

			const { updateDoc } = useDocument(checklistRef);

			const updates = {
				status: "active",
				completedAt: null,
				updatedAt: date,
			};

			try {
				await updateDoc(updates);
			} catch (e) {
				error.value = "Error marking checklist complete";
				downloading.value = false;
			}
			checklist.value.status = "active";
			completedOn.value = null;
			markedComplete.value = null;
			downloading.value = false;
		};

		return {
			checklist,
			pageHasValues,
			hasSelected,
			isLoading,
			generateReport,
			downloading,
			filename,
			error,
			sectionHasValues,
			moment,
			hasLength,
			archiveChecklist,
			markChecklistAsComplete,
			markedComplete,
			markChecklistAsActive,
			unArchiveChecklist,
			cookieId,
			pageAccess,
			downloadPdf,
			generatingPdf,
			pdf,
			props,
			companyName,
			companyLogo,
			fullName,
			editAccess,
		};
	},
});
</script>

<style lang="scss">
.archived-banner {
	width: 100%;
	text-align: center;
	background-color: $rm-danger;
	color: white;
	padding: 0.5em;
}

.btn-loading {
	padding: 0.5em 1em;
}

.img-shimmer {
	height: 180px;
	width: 180px;
	border-radius: 8px;
}

.title-shimmer {
	height: 2em;
	width: 40ch;
	border-radius: 5px;
}

.lines {
	height: 1.2em;
	width: 30ch;
	margin-bottom: 10px;
	border-radius: 5px;
}

.cl-container {
	width: 100%;
	max-width: 794px;
	margin: 2em auto;
	padding: 1em;
	background-color: white;
	border-radius: 4px;

	.ql-align-left {
		text-align: left !important;
	}

	.ql-align-right {
		text-align: right !important;
	}
	.ql-align-center {
		text-align: center !important;
		img {
			max-width: max-content;
		}
	}
}

.cl-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0.5em;
	border-bottom: 1px solid $rm-light-grey;
	margin-bottom: 1em;
	// gap: 2em;
	gap: 1em;

	@include respond(small) {
		gap: 2em;
	}

	.btn-icon {
		font-size: 14px !important;
		gap: 5px !important;

		@include respond(small) {
			font-size: 16px !important;
			gap: 8px !important;
		}
	}
}

.cl-body {
	p {
		.ql-align-center {
			text-align: center !important;
		}
	}

	.cover-page {
		position: relative;
		min-height: 100vh;
		padding: 1em 0;

		.cover-page-bottom {
			margin: 5em 0 0 1em;
			border-left: 4px solid $rm-dark-blue;
			padding-left: 1em;
		}

		.logo {
			max-width: 180px;
			margin: 0 auto;
			padding: 1em 0;
		}

		.title {
			width: 100%;
			text-align: center;
			padding-bottom: 3em;

			h1 {
				font-size: 22px;

				@include respond(small) {
					font-size: 24px;
				}
			}
		}

		.detail {
			display: flex;
			flex-direction: column;
			margin-bottom: 1em;

			h4 {
				margin-bottom: 0.5em;
			}

			a {
				color: $rm-blue;
				text-decoration: underline;

				&:hover {
					font-weight: normal;
				}
			}
		}
	}

	.page {
		border-top: 1px solid $rm-light-grey;
		padding: 1em;
		min-height: 100vh !important;

		.page-title {
			padding: 1em 0 0.5em;
			border-bottom: $rm-dark-blue 3px solid;

			h2 {
				font-size: 20px;

				@include respond(small) {
					font-size: 22px;
				}
			}
		}

		.section-title {
			margin-top: 0.5em;
			padding: 1em 0 0.5em;
			border-bottom: 3px solid $rm-blue;

			h3 {
				font-size: 18px;

				@include respond(small) {
					font-size: 20px;
				}
			}
		}

		.question-container {
			margin-top: 0.5em;
			background-color: $rm-off-white;
			padding: 0.5em 1em;
		}

		.equipment-message {
			display: flex;
			gap: 0.5em;
			align-items: center;
			color: $rm-dark-grey;
			font-size: 0.8em;
		}

		.actions {
			display: flex;
			flex-direction: column;
			gap: 1em;
			width: auto;
			height: auto;
			border-left: 8px solid $rm-off-white;

			.header {
				border-bottom: 2px solid $rm-grey;
				padding: 0.5em;
			}

			.incomplete {
				padding: 0.5em 1em;

				.header {
					color: #b40000;
					border-bottom: 2px solid #b40000;
					padding: 0.5em;
				}
			}

			.complete {
				padding: 0.5em 1em;
			}

			.notes {
				padding: 0.5em 1em;

				.content {
					margin-top: 0.5em;
					padding: 1em;
					background-color: $rm-off-white;
				}
			}

			.images {
				padding: 0.5em 1em;

				.image-grid {
					display: grid;
					margin-top: 0.5em;
					padding: 1em;
					grid-template-columns: repeat(5, 100px);
					grid-template-rows: auto;
					column-gap: 1em;
					row-gap: 1em;
					justify-items: center;
					align-items: center;
					background-color: $rm-off-white;

					.image {
						overflow: hidden;
					}
				}
			}
		}

		.progress {
			padding: 1em 1em 0;
			text-transform: uppercase;
			font-size: 0.8em;
			font-weight: bold;
			color: $rm-dark-grey;
		}

		.actions-list {
			margin-top: 0.5em;
			display: flex;
			flex-direction: column;
			gap: 0.5em;
		}
	}

	.actions-warning {
		font-size: 0.8em;
		color: $rm-grey;
		margin: 1em;
	}
}

img {
	max-width: 100%;
}

// .action-update {
// 	display: flex;
// 	align-items: center;
// 	margin-right: 1em;
// 	gap: 1em;
// 	font-size: 0.8em;
// 	font-weight: bold;

// 	* {
// 		background-color: $rm-light-grey;
// 		border-radius: 4px;
// 		padding: 0.5em;
// 		text-align: center;
// 		width: 15ch;
// 	}
// }

.checked-complete {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;

	.icon {
		color: $rm-success;
		font-size: 1.5em;
	}

	.icon-incomplete {
		color: $rm-grey !important;
		font-size: 1.5em;
	}
}

.cl-footer {
	margin-top: 1em;
	border-top: 1px solid $rm-light-grey;
	text-align: center;
	padding: 0.5em;

	p {
		font-size: 0.8em;
	}
}
</style>
